import * as React from "react";
import {createContext, useContext} from "react";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import RichTextBlock from "../../components/cms/RichTextBlock";
import QuoteBlock from "../../components/cms/QuoteBlock";
import Grid from "@mui/material/Grid";
import Moment from "react-moment";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ImageSliderBlock from "../../components/cms/ImageSliderBlock";
import {FirebaseContext} from "../../state/firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import {useI18next} from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ArticleQuery($slug: String!, $language: String!) {
    locales: allLocale (filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    articles: allStrapiArticle(
      limit: 15
      filter: {locale: {eq: $language}, article: {id: {eq: null}}}
      sort: {fields: [order], order: ASC}
    ) {
      edges {
        node {
          strapiId
          locale
          slug
          title
          category {
            name
          }
          articles {
            ... on StrapiArticle {
              id
              title
              slug
              articles {
                ... on StrapiArticle {
                  id
                  title
                  slug
                }
              }
            }
          }
        }
      }
    }
    strapiArticle(slug: {eq: $slug}, locale: {eq: $language}) {
      locale
      slug
      localizations {
        locale
        slug
      }
      title
      description
      childrenComponentSharedRichText {
        id
        blockId
        childMdx {
          body
        }
      }
      blocks {
        __typename
        ... on StrapiComponentSharedRichText {
          id
        }
        ... on StrapiComponentSharedMedia {
          id
          file {
            caption
            file {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        ... on StrapiComponentSharedQuote {
          id
          title
          body
        }
        ... on StrapiComponentSharedSlider {
          id
          files {
            caption
            alternativeText
            file {
              id
              childImageSharp {
                gatsbyImageData(width: 400, placeholder: BLURRED)
              }
            }
          }
        }
      }
      publishedAt
      cover {
        file {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      author {
        name
        avatar {
          file {
            childImageSharp {
              gatsbyImageData(width: 30, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export interface ArticleLangData {
  localizations?: any[];
}

export const ArticleLangContext = createContext<ArticleLangData>(null);

const Article = ({data}) => {
  const {navigate} = useI18next();
  if (!data.strapiArticle) {
    navigate("/");
    return null;
  }
  const {firebase} = useContext(FirebaseContext);
  const [user, loading] = useAuthState(getAuth(firebase));

  if (loading) {
    return null;
  }
  if (!loading && !user && location.pathname !== `/app/login`) {
    navigate("/app/login");
    return null;
  }

  const article = data.strapiArticle;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  const renderBlock = (block, article) => {
    switch (block.__typename) {
      case "StrapiComponentSharedRichText":
        // Hacky as we're converting String markdown to MDX and need to get the
        // MDX instead here... see gatsby-node.js for details
        // there's an open issue on strapi-source to put mediaType on RichText
        const mdxBody = article.childrenComponentSharedRichText
            .filter((child) => child.blockId === block.id)
            .map((child) => child.childMdx.body)[0];
        return (
            <RichTextBlock key={`${block.__typename}_${block.id}`}
                           body={mdxBody}/>
        );
      case "StrapiComponentSharedQuote":
        return (
            <Box sx={{marginBottom: 2}}>
              <QuoteBlock key={`${block.__typename}_${block.id}`}
                          body={block.body}
                          title={block.title}/>
            </Box>
        );
      case "StrapiComponentSharedMedia":
        return (
            <Box sx={{marginBottom: 2}}>
              <GatsbyImage key={`${block.__typename}_${block.id}`}
                           alt={block.file.caption}
                           image={getImage(block.file.file)}
              />
            </Box>
        );
      case "StrapiComponentSharedSlider":
        return (
            <Box sx={{marginBottom: 2}}>
              <ImageSliderBlock key={`${block.__typename}_${block.id}`}
                                images={block.files}/>
            </Box>
        );
    }
  };

  let langContext = {
    localizations: [{
      locale: article.locale,
      slug: article.slug,
    }].concat(article.localizations)
        .filter(value => !!value),
  };
  return (
      <ArticleLangContext.Provider
          value={langContext}>
        <Layout seo={seo}
                articles={data.articles}
        >
          <Grid container>
            <Grid item sx={{flexGrow: 1}}>
              {article.cover && (
                  <GatsbyImage alt={`Picture for ${article.title} article`}
                               image={getImage(article.cover.file)}
                  />
              )}
              <Grid item container sx={{p: 2, flexDirection: "column"}}>
                <Grid item sx={{alignSelf: "center"}}>
                  <Typography variant="h2"
                              gutterBottom
                              color="inherit">
                    {article.title}
                  </Typography>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item>
                    <Typography>
                      By {article.author.name}
                    </Typography>
                    <Typography>
                      <Moment
                          format="MMM Do YYYY">{article.publishedAt}</Moment>
                    </Typography>
                  </Grid>
                  <Grid item alignSelf="center">
                    {article.author.avatar && (
                        <GatsbyImage
                            image={getImage(article.author.avatar.file)}
                            alt={`Picture of ${article.author.name}`}
                            style={{borderRadius: "50%"}}
                        />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container sx={{p: 2, flexDirection: "column"}}>
                {article.blocks.map((block, idx) => {
                  return (
                      <Grid item key={idx}>
                        {renderBlock(block, article)}
                      </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </ArticleLangContext.Provider>
  );
};

export default Article;
