import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

interface QuoteBlockProps {
  title: string;
  body: string;
}

export default (props: QuoteBlockProps) => {
  return (
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Divider variant="inset"/>
        <Typography variant="subtitle2"
                    gutterBottom
                    sx={{alignSelf: "flex-end", paddingTop: 2}}>
          {props.body}
        </Typography>
        <Divider variant="inset"
                 textAlign="right">
          <Typography variant="caption">
            {props.title}
          </Typography>
        </Divider>
      </Box>
  );
}
