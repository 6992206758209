import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

import {useTheme} from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import {autoPlay} from "react-swipeable-views-utils";
import Grid from "@mui/material/Grid";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default ({images}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
      <Grid container justifyContent="center">
        <Grid item>
          <Box sx={{maxWidth: 400, flexGrow: 1}}>
            <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 50,
                  pl: 2,
                  bgcolor: "background.default",
                }}
            >
              <Typography>{images[activeStep].alternativeText}</Typography>
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
              {images.map((step, index) => (
                  <div key={`${step.label}_${index}`}>
                    {Math.abs(activeStep - index) <= 2 ? (
                        <Box sx={{
                          height: 255,
                          display: "block",
                          maxWidth: 400,
                          overflow: "hidden",
                          width: "100%",
                        }}
                        >
                          <GatsbyImage alt={step.caption}
                                       image={getImage(step.file)}
                          />
                        </Box>
                    ) : null}
                  </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft/>
                    ) : (
                        <KeyboardArrowRight/>
                    )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack}
                          disabled={activeStep === 0}>
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight/>
                    ) : (
                        <KeyboardArrowLeft/>
                    )}
                    Back
                  </Button>
                }
            />
          </Box>
        </Grid>
      </Grid>
  );
};
